.table {
  /* padding: 10% 1% 10% 5% !important; */
  width: 100% !important;
  border-radius: 10px !important;
  max-height: 80vh !important;
}

.tableInner thead {
  position: sticky !important;
  top: 0% !important;
  z-index: 2 !important;
}

.tableInner thead tr td {
  padding: 3px 0px 0px 0px !important;
  font-size: x-small;
  text-align: center;
}

.tableInner thead tr th {
  padding: 3px 0px 5px 0px !important;
  font-size: x-small;
  text-align: center;
  font-weight: 600;
}

.main {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 2%;
}

.tdModerate {
  width: 7%;
  vertical-align: middle !important;
  font-size: x-small !important;
}

.td {
  vertical-align: middle !important;
  font-size: x-small !important;
}

.tdLess {
  vertical-align: middle !important;
  font-size: x-small !important;
}

.tdLess:hover,
.td:hover {
  cursor: default;
}

.tableRow {
  text-align: center;
}

.fullWidth {
  text-align: center;
}

.badgeDot {
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  color: #2299c5;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  margin: 0px 0px 3px 0px;
}