.App {
  height: 100vh;
  background-color: white;
  color: black;
}
.listCont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ul {
  overflow-y: scroll;
  height: 150px;
  padding: 20px;
}
.li,
.active {
  padding: 10px;
  border-radius: 5px;
}
.li:hover,
.li:focus,
.active {
  background-color: black;
  color: white;
  transition: 0.5s;
}
