.renderQuill img {
  width: 35%;
  height: 80px;
  object-fit: contain;
}

.commCont {
  padding: 1rem;
  padding-left: 0;
}
