.messageList {
  height: 88vh;
  background-color: white;
}
.viewMessagesList {
  background-color: white;
  height: 54vh;
}

.messageListContainer {
  /* display:flex;
    flex-direction:column-reverse; */
  height: 68vh;
  background-color: white;
  overflow-y: auto;
  padding: 10px;
  scroll-behavior: smooth;
}
