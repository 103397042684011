#msgMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nameStamp {
  font-size: x-small !important;
  font-family: 'GothamBook',sans-serif !important;
  text-align: start !important;
  font-weight: 700;
  color: rgb(22, 153, 197) !important;
}

#msgActualBody {
  height: 85vh;
  max-height: 85vh !important;
  min-height: 85vh !important;
}

.msgActlBody {
  display: grid;
  grid-template-rows: 56px auto 100px;
  background-color: #fff;
}

#customMsgHead {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #1699c5;
  color: white;
}

#menuItem {
  width: 33.3%;
  text-align: center;
  background-color: transparent;
}

#menuItem:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
}

#menuItemSelected {
  width: 33.3%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}

#textBox {
  width: 100%;
  border: none;
  background-color: white;
  border-bottom: 1px solid #c1c1c1;
  outline: none;
  resize: none;
}

#cardMainMD {
  background-color: rgb(245, 245, 245);
  width: 85%;
  margin: 2%;
  padding: 2% 5% 3% 5%;
  border-radius: 6px;
  font-weight: 500;
  overflow-wrap: break-word;
}

#cardmainBlock {
  text-align: center;
  background-color: rgb(202 201 201 / 19%);
  width: 75%;
  border-radius: 6px;
  font-weight: 500;
  padding: 7px 0px;
}

#cardPatientBlock {
  margin-left: auto;
  text-align: center;
  background-color: rgb(202 201 201 / 19%);
  padding: 6px;
  width: 85%;
  border-radius: 6px;
  font-weight: 500;
}

#cardMainMDimage {
  width: 100%;
  height: 100px;
  font-weight: 500;
  border: 2px solid rgb(22, 153, 197);
  object-fit: cover;
}

#cardMainPatientimage {
  width: 100%;
  height: 100px;
  font-weight: 500;
  border: 2px solid rgb(22, 153, 197);
}

#cardMainMDimageblock {
  width: 80%;
  background-color: rgb(245, 245, 245);
  margin: 2%;
  border-radius: 5px;
  padding: 5px;
}

#cardPatientImage {
  background-color: rgb(245, 245, 245);
  margin: 2%;
  padding: 5px;
  margin-left: auto;
  width: 80%;
  border-radius: 5px;
}

#cardMainMDPending {
  background-color: rgb(239 239 239 / 74%);
  width: 85%;
  margin: 2%;
  padding: 2% 5% 3% 5%;
  border-radius: 6px;
  color: black;
  font-weight: 400;
  animation-name: sendingimage;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  opacity: 0.9;
}

@keyframes sending {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0.8;
  }
}

#videopPending {
  margin-top: 3%;
  margin-left: 5px;
  opacity: 0.9;
  width: 100%;
}

.pendingVideotag {
  width: 80%;
  height: 100px;
  margin-left: auto;
}

#audioPending {
  background-color: rgb(239 239 239 / 74%);
  border-radius: 5px;
  width: 85%;
  margin: 2%;
  padding: 5px;
  opacity: 0.9;
}

#audioPending audio {
  width: 100%;
  height: 25px;
  margin-left: -5px;
}

.mdAudiomsg {
  background-color: rgb(239 239 239 / 74%);
  border-radius: 5px;
  width: 85%;
  margin: 2%;
  padding: 5px;
}

.mdAudiomsg audio {
  width: 100%;
  height: 25px;
  margin-left: -5px;
}

#imagePending {
  margin-top: 3%;
  margin-left: 5px;
  /* animation-name: sendingimage;
  animation-duration: 2s;
  animation-iteration-count: infinite; */
  opacity: 0.9;
  width: 80%;
}

.imagePending {
  width: 100%;
  height: 100px;
  margin-left: auto;
  border-radius: 8px;

  object-fit: cover;

  border: 2px solid rgb(22, 153, 197);
}

@keyframes sendingimage {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.9;
  }
}

#textPnding {
  width: 85%;
  margin: 2%;
  border-radius: 6px;
  color: black;
  font-weight: 500;
  opacity: 0.9;
  overflow-wrap: break-word;
}

.chatSkeltonvideo {
  width: 70%;
  margin-left: auto;
}

.chatSkeltonimage {
  width: 70%;
  margin-right: auto;
}

.chatSkeltonText {
  width: 80%;
  margin-right: auto;
}

.chatSkeltonTextinBound {
  width: 80%;
  margin-left: auto;
}

#cardMainPatient {
  background-color: rgb(255, 181, 181);
  margin: 2%;
  margin-left: auto;
  width: 85%;
  padding: 2% 5% 3% 5%;
  border-radius: 6px;
  font-weight: 500;
  overflow-wrap: break-word;
}

/* patient messages */

.cardPatientVideo {
  width: 80%;
  margin: 2%;
  border-radius: 5px;
  padding: 5px;
  background-color: rgb(255, 181, 181);
}

/* cardPatientVideomsg */
.cardPatientVideo video {
  width: 100%;
  border: 2px solid rgb(22, 153, 197);
}

#cardPatientImage img {
  width: 100%;
  height: 100px;
  font-weight: 500;
  border: 2px solid #e53a9166;
  object-fit: cover;
}

.patientAudio {
  border-radius: 5px;
  width: 85%;
  margin: 2%;
  padding: 5px;
  margin-right: auto;
  background-color: rgb(255, 181, 181);
}

.msgLink {
  text-decoration: none;
  color: black;
}

.msgLink:hover {
  text-decoration: none;
  color: black;
}

/* patientAudiomsg */

.patientAudio audio {
  width: 100%;
  height: 25px;
  margin-left: -5px;
}

/* patient messages */

.mdVideo {
  width: 80%;
  background-color: rgb(245, 245, 245);
  margin: 2%;
  border-radius: 5px;
  padding: 5px;
}

.mdVideo video {
  width: 100%;
  border: 2px solid rgb(22, 153, 197);
}

#cardBody {
  font-size: small !important;
  font-weight: 600;
  word-wrap: break-word;
}

#cardTimeStamp {
  margin-top: 0 !important;
  font-weight: 600;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook',sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook',sans-serif;
}

::placeholder {
  color: #c1c1c1;
  font-size: 14px;
  font-family: 'GothamBook',sans-serif;
}

#customBody {
  height: 75%;
}

#customBody::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
}

#customBody::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

#customBody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dddddd;
}

/* 
@media (min-width: 1200px) {

  #cardMainMDimage, .imagePending {
    height: 150px;
  }

} */
@media only screen and (max-width: 2200px) {
  #cardPatientImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideotag {
    height: 260px;
  }
}

@media only screen and (max-width: 1800px) {
  #cardPatientImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideotag {
    height: 230px;
  }
}

@media only screen and (max-width: 1560px) {
  #cardPatientImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideotag {
    height: 190px;
  }
}

@media only screen and (max-width: 1350px) {
  #cardPatientImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideotag {
    height: 140px;
  }
}

@media only screen and (max-width: 1200px) {
  #cardPatientImage img,
  #cardMainMDimage,
  .imagePending,
  .pendingVideotag {
    height: 130px;
  }
}

@media only screen and (max-width: 992px) {
  #cardPatientImage img,
  #cardMainMDimageblock img,
  .imagePending,
  .pendingVideotag {
    height: 115px;
  }
}

@media only screen and (max-width: 768px) {
  #cardPatientImage img,
  #cardMainMDimageblock img,
  .imagePending,
  .pendingVideotag {
    height: 100px;
  }
}

@media only screen and (max-width: 420px) {
  #cardMainMDimageblock img,
  .imagePending,
  .pendingVideotag {
    height: 50px;
  }
}

/*  Phrase css for communication center */

#mainPhraseModal {
  position: absolute;
  z-index: 99999;
  bottom: 62px;
  background: whitesmoke;
  height: 50vh;
  right: 0;
  padding-top: 4px;
  box-shadow: 5px 5px #f4fcfd;
  overflow: auto;
  width: -webkit-fill-available;
  width: -moz-available;
}

#phrase_wrapper {
  display: flex;
  justify-content: space-between;
  border: 0.3px solid #d5cccc;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 2px;
}

#phrase_Items {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
  width: 350px;
  font-size: 12px;
  font-weight: bold;
}

#phrase_Items:hover {
  background: whitesmoke;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
