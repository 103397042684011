#addNewUserModalHeader {
  border: none;
  padding-bottom: 0px;
}

.customDialogSize {
  max-width: 40% !important;
}

#addNewUserModalTitle {
  color: black !important;
  font-size: small;
  font-weight: bold;
}

.withoutBorder {
  width: 100%;
  margin-top: 25px;
}

.withoutBorder fieldset {
  border-radius: 0px;
  padding: 5px;
}

#addNewUserModalButton {
  width: 30%;
  border-radius: 32px 32px 32px 32px;
  font-size: small;
  padding: 1% 0%;
}

.sectionHeadingText {
  margin: 0;
  font-size: small !important;
  font-weight: 600 !important;
}

.errorCustom {
  border: 1px solid red !important;
}

.widthCustom {
  width: 32%;
  margin-left: 1%;
}

.extension {
  width: 10%;
}

#selectorAddUser {
  border: 1px solid rgba(221, 221, 221, 1);
  color: #838a8a;
  font-size: small;
  font-family: 'GothamBook',sans-serif;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selectedSelectorAddUser {
  border: 1px solid rgba(221, 221, 221, 1);
  color: black;
  font-family: 'GothamBook',sans-serif;
  font-size: small;
  width: 100%;
  padding: 3.5% 2% 3.5% 1%;
}

#selectorAddUser:focus {
  outline: none;
  box-shadow: none;
}

#selectedSelectorAddUser:focus {
  outline: none;
  box-shadow: none;
}

#customLabel {
  margin: 0 5px !important;
  font-size: xx-small !important;
  font-weight: bold !important;
  color: black !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#additionalRole {
  width: 65%;
  margin-bottom: 2%;
  margin-left: 0 !important;
}
.assingRoleLabel {
  margin-left: 0 !important;
}

label {
  margin: 0 2% !important;
  font-size: small;
}

@media only screen and (max-width: 768px) {
  /* For medium devices: */
  #addNewUserModalButton {
    width: 70%;
    padding: 3% 0%;
  }

  .widthCustom {
    width: 100%;
    margin-left: 0;
    margin-top: 2%;
  }

  #additionalRole {
    width: 100%;
  }
}

.datePickerCustomeStyle {
  /* outline: none !important; */
  /* border-style: solid !important; */
  /* border-color: rgb(221, 221, 221) !important; */
  /* border-width: 1px !important; */
  vertical-align: middle !important;
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  height: 36px !important;
  /* width: 228px !important; */
}

.react-date-picker__wrapper {
  border: thin solid #dddddd !important;
  width: 245.11px !important;
}

@media only screen and (max-width: 768px) {
  .extension {
    width: 20%;
    margin-left: 0;
  }
}
