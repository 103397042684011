#practiceContainer {
    width: 100%;
    margin-top: 3%;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

#head {
    background-color: transparent; 
}
#headText {
    font-weight:550;
    color: black;
    margin:0;
}

#headButton {
    font-size: xx-small;
    border-radius: 32px 32px 32px 32px;
    padding: 0.5% 2%;
    width: 15%;
    min-width: 115px;
}

#body {
    font-size: small;
    font-family:'Arial',sans-serif;
    color: rgb(175, 172, 172);
}

.customCol1 {
    font-weight: bold;
    padding-left: 4% !important;
    color: black;
    width: 13%;
}

.customCol2 {
    width: 25%;
    min-width: 177px;
}

.customCol3 {
    width: 20%;
    min-width: 142px;
}

.customCol4 {
    width: 12%;
    min-width: 86px;
}

.customCol5 {
    width: 10%;
    min-width: 71px;
}

.customCol6 {
    width: 20%;
    text-align: end;
    padding-right: 4% !important;
    min-width: 45px;
}

#editButton {
    font-size: xx-small;
    border-radius: 32px 32px 32px 32px;
    padding: 2% 16%;
    width: 60%;
    min-width: 40px;
}

