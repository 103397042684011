#main {
  background-color: rgba(246, 252, 253, 1);
  width: 100%;
  height: 100vh;
}

#container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 29.75%;
  height: 17.88%;
  margin-top: 9%;
  margin-bottom: 2%;
  min-width: 195px;
}

#bannerError {
  background-color: rgba(255, 210, 210, 1);
  border: 1px solid rgba(255, 162, 162, 1);
  width: 22.69%;
  min-width: 290px;
  text-align: start;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  padding-left: 1.5%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px 10px 10px 10px;
}

#bannerErrorText {
  font-size: xx-small;
  line-height: 5px;
  color: rgba(224, 32, 32, 1);
  font-family: "GothamBook",sans-serif;
  font-weight: bold;
}

#form {
  display: flex;
  flex-direction: column;
  width: 21.69%;
  min-width: 290px;
}

input {
  outline: none;
  border-style: solid;
  border-color: rgba(221, 221, 221, 1);
  border-width: 1px;
  vertical-align: middle;
}

a {
  margin-top: 3%;
  font-size: xx-small;
  color: rgba(13, 91, 118, 1);
  font-family: "GothamBook",sans-serif;
}

#remember {
  margin-top: 3%;
}

label {
  margin-left: 2%;
  font-size: x-small;
  font-family: "GothamBook",sans-serif;
  color: rgba(119, 126, 131, 1);
}

#button {
  width: 58%;
  background-color: transparent;
  border: 1px solid rgba(22, 153, 197, 1);
  color: rgba(13, 91, 118, 1);
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 32px 32px 32px 32px;
  font-family: "GothamBook",sans-serif;
}

#button:focus {
  outline: none;
  box-shadow: none;
}

#button:hover {
  background-color: rgba(22, 153, 197, 1);
  color: white;
}

#buttonHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.field {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  margin-top: 2%;
  font-size: 12px;
}

.errorCustom {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  margin-top: 2%;
  font-size: 12px;
  border: 1px solid red;
}

#divider {
  width: 80%;
}

#footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#footerText {
  font-size: 10px;
  color: rgba(119, 126, 131, 1);
  font-family: "GothamBook",sans-serif;
  padding: 0% 0% 1% 0%;
}
#footerUdi{
  font-size: 10px;
  color: rgba(119, 126, 131, 1);
  font-family: "GothamBook",sans-serif;
}


::-webkit-input-placeholder {
  /* Edge */
  color: rgba(119, 126, 131, 1);
  font-size: 10px;
  font-family: "GothamBook",sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(119, 126, 131, 1);
  font-size: 10px;
  font-family: "GothamBook",sans-serif;
}

::placeholder {
  color: rgba(119, 126, 131, 1);
  font-size: 10px;
  font-family: "GothamBook",sans-serif;
}
