.dot {
  height: 25px;
  width: 25px;
  background-color: rgb(209, 35, 35);
}

.rounded {
  border-radius: 50%;
  display: inline-block;
}
